<template>
	<div class="filter-field filter-field-media">
		<BaseDropdown
			:clickaway="clickaway"
			text="Media"
			title="Nach Media filtern"
		>
			<FilterItem
				v-for="(item, key) in allMedia"
				:key="key"
				:current="media"
				:item="item"
				@change="_onChange"
			/>
		</BaseDropdown>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';
import {groupBy} from '@schascha/brabbelback';

export default {
	components: {
		FilterItem: () => import(/* webpackChunkName: "FilterItem" */ '@/components/filter/FilterItem')
	},
	mixins: [FilterMixin],
	props: {
		clickaway: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState(['media']),
		allMedia() {
			return groupBy(this.$store.state.allMedia, 'type');
		}
	},
	methods: {
		_onChange(e) {
			const {name, year} = e;
			this.changeFilter('media', `${name} (${year})`);
		}
	}
};
</script>
